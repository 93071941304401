.friends_list {
    @include mq(md, min) {
        width: 45%;
        margin-right: 10%;
        &:nth-child(even) {
            margin-right: 0;
        }
        margin-bottom: 15px;
    }
}

.friends_img {
    box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 3px 1px;
}

.friends_text {
    width: 90%;
    margin: 0 auto;
    vertical-align: top;

}
.friends_title {
    font-size: 2.2rem;
    font-weight: bold;
}
