.c_mv {
    position: relative;
    width: 100%;
    background: #000;

    &-img {
        display: block;
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
    }

    /* script実行時にbx-sliderにセットした画像が全部表示されてしまうので対策 */
    &-show {
        display: block;
    }

    &-hide {
        display: none;
    }
    /* 対策ココマデ */

    /** bx-sliderの不要なstyleを上書き **/
    .bx-wrapper {
        position: relative;
        width: 100%;
        margin: 0 auto 60px;
        padding: 0;
        border: none;
        box-shadow: none;
        background: #000;
        img {
            display: block;
            margin: 0 auto;
            max-width: $max-width;
        }
    }
    /** 上書きココマデ **/
}

