.c_heading3 {
    position: relative;
    margin: 0 0 30px;
    font-weight: 800;
    text-align: center;
    line-height: 1em;
    @include mq(md) {
        margin: 0 0 15px;
    }

    > span {
        position: relative;
        z-index: 2;
        display: inline-block;
        margin: 0 2.5em;
        padding: 0.2em 2em;
        background-color: #f3f1ed;
        font-size: 1.8rem;
        text-align: left;
    }

    &::before {
        position: absolute;
        top: 50%;
        z-index: 1;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #ccc;
    }
}
