.c-pagettl {
    margin: 15px auto 0;

    &_heading {
        @include mq(md) {
            font-size: 2.2rem;
        }
        @include mq(md, min) {
            font-size: 2.5rem;
        }
    }

}

.single .c-pagettl p.singletitle {
    font-size: 1.8rem;
}