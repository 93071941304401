#normal {
    .l-mainwrap {
        margin-bottom: 60px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 3px 1px;
    }

    .content-inner {
        padding: 30px;
    }

    h4.title {
        margin: 5px 0;
        line-height: 1.8;
    }

    .day {
        font-size: 1.2rem;
        margin: 0 10px 15px;
    }

    .des {
        font-size: 1.4rem;
        line-height: 1.8em;
    }

    .postlist li {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #a0a0a0;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }

        a {
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 767px) {
    #normal {
        .postlist li .posttxt {
            .title {
                margin: 0;
                padding: 5px 0;
                font-size: 1.2rem;
            }

            .des {
                font-size: 1.2rem;
                padding: 8px;
            }
        }

        .img {
            text-align: center;
            margin: 0 0 15px;
        }

        .posttxt {
            p {
                margin-bottom: 5px;
            }

            a {
                font-size: 2rem;
                font-weight: bold;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #normal {
        .img {
            float: left;
            width: 30%;
            margin-right: 3%;
        }

        .posttxt {
            width: 67%;
            float: left;
            line-height: 1.5em;

            .title a {
                font-size: 1.8rem;
                font-weight: bold;
            }
        }
        .noimg {
            width: 100%;
            float: none;
        }
    }
}