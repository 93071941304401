.category {
    .nopost {
        text-align: center;
    }

    .entry-header a {
        color: #cc3314;
        text-decoration: none;
    }
}

/* wp_social_bookmarking_light */

.wp_social_bookmarking_light {
    display: block;
    padding-top: 30px;
}


.single .content-dir .wp_social_bookmarking_light img {
    margin: 0;
    box-shadow: none;
}
