/// Breakpoint
$breakpoints: () !default;
$breakpoints: map-merge(
    (
        xs: 0,
        sm: 576px,
        md: 767px,
        lg: 990px,
        xl: 1200px
    ),
    $breakpoints
);

/// Media Queries
@mixin mq($size, $width: max) {
    $result: 0;
    @if(type-of($size) == number) {
        $result: $size;
    } @else {
        @each $key, $value in $breakpoints {
            @if($key == $size) {
                $result: $value;
            }
        }
    }
    @if($width == min) {
        $result: $result + 1;
    }
    @media (#{$width}-width: $result) {
        @content;
    }
}

/// CSS Default Font Size
$css-default-font-size: 1.6rem;

$max-width: 960px;

/// zindex settings
$zindex-dropdown:       1000 !default;
$zindex-sticky:         1020 !default;
$zindex-fixed:          1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal:          1050 !default;
$zindex-popover:        1060 !default;
$zindex-tooltip:        1070 !default;
$zindex-backtotop:      1080 !default;

$font-weight-lighter: lighter !default;
$font-weight-light:   300 !default;
$font-weight-normal:  400 !default;
$font-weight-bold:    700 !default;
$font-weight-bolder:  bolder !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 4.5),
        7: ($spacer * 6),
    ),
    $spacers
);

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;