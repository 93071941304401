/* grtdir ---------------------------------*/
.grtdir {
    &_wrap {
        max-width: 100%;
        @include mq(md) {
            max-width: 95%;
        }
    }

    &_inner {
        max-width: 800px;
    }

    &_text {
        @include mq(md) {
            line-height: 1.4em;
        }
        @include mq(md, min) {
            width: 65%;
            margin-right: 5%;
            line-height: 1.8em;
        }
    }

    &_illust {
        width: 30%;
        @include mq(md) {
            margin-left: auto;
        }

        &:hover {
            animation: rumble 0.12s linear infinite;
        }

        @keyframes rumble {
            0% { transform: rotate(0deg) translate(0, 0); }
            12.5% { transform: rotate(0.4deg) translate(1px, -1px); }
            25% { transform: rotate(0.8deg) translate(0px, 1px); }
            37.5% { transform: rotate(0.4deg) translate(-1px, 0); }
            50% { transform: rotate(0deg) translate(0, 0); }
            62.5% { transform: rotate(-0.4deg) translate(1px, 0); }
            75% { transform: rotate(-0.8deg) translate(0, 1px); }
            87.5% { transform: rotate(-0.4deg) translate(-1px, -1px); }
            100% { transform: rotate(0deg) translate(0, 0); }
        }
    }
}


/* topblog ---------------------------------*/
.topblog {
    @include mq(md, min) {
        width: 48%;
        min-height: 215px;
        margin-right: 4%;
    }

    &_item {
        @include mq(md) {
            border-bottom: 1px dashed #ccc;
            &:last-child {
                border: none;
            }
        }
    }

    &_day, &_title {
        display: inline-block;
        vertical-align: middle;
    }

    &_day {
        font-size: 1.4rem;
        @include mq(md) {
            font-size: 1.2rem;
        }
    }

    &_title {
        @include mq(md) {
            display: block;
            font-size: 1.4rem;
        }
    }

    &_link {
        width: 60%;
        background: var(--gray-1);
        font-size: 1.2rem;
    }
}

/* movie-block ---------------------------------*/
.topmovie {
    @include mq(md) {
        width: 90%;
    }

    @include mq(md, min) {
        width: 48%;
    }

    iframe {
        border-radius: 8px;
    }
}

/* record ---------------------------------*/
.toprecord {
    &_item {
        @include mq(md) {
            width: 49%;

            &:nth-child(odd) {
                margin-right: 2%;
            }
        }
        @include mq(md, min) {
            width: 23.5%;
            margin-right: 2%;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &_thumb {
        width: 100%;
    }
}

/* friends ---------------------------------*/
.topfriends {
    &_item {
        position: relative;

        @include mq(md) {
            width: 48%;
            margin-right: 4%;

            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @include mq(md, min) {
            width: 32%;
            margin-right: 2%;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    &_img {
        width: 100%;
        border-radius: 10px;
    }

    &_title {
        position: absolute;
        top: 30px;
        right: 0;
        margin: auto;
        padding: 3px 10px;
        border-radius: 5px;
        background: var(--white);
        color: var(--gray-10);

        @include mq(md) {
            margin: auto;
            font-size: 1.2rem;
        }
    }
}
