.c-breadcrumb {
    margin: 0 auto;

    &_list {
        clear: both;
        overflow: hidden;
        text-align: center;

    }

    &_item {
        display: inline-block;
        padding: 0 15px 0 0;
        font-size: 1.4rem;
        line-height: 1.8;

        &:last-child {
            display: inline-block;
            padding: 0;
        }

    }

    &_link {
        color: #A0DFE3;
        text-decoration: underline;

        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }
}
