#content {
    @include mq(md) {
        margin: 30px auto 0;
        min-height: 320px;
    }

    @include mq(md, min) {
        margin: 60px auto 0;
        min-height: 640px;
    }
}

.content-dir {
    margin-bottom: 60px;
    @include mq(md) {
        margin-bottom: 30px;
    }
}

.content-inner {
    padding: 15px;
}

.l-mainwrap {
    @include mq(md) {
        width: 97%;
        margin: 0 auto;
    }
    @include mq(md, min) {
        max-width: $max-width;
        margin: 0 auto;
    }
}
