.record_bg {
    background: rgba(255, 255, 255, 0.5);
}

.record_title {
    @include mq(md) {
        font-size: 1.8rem;
    }
    @include mq(md, min) {
        margin-bottom: 30px;
        font-size: 2.8rem;
        line-height: 1.2;
    }
}

.record_text {
    line-height: 2em;
}

.record_text {
    @include mq(md) {
        font-size: 1.4rem;
    }
}

.record_img {
    @include mq(md, min) {
        width: 40%;
        margin-right: 5%;
    }
}

.record_text_wrap {
    @include mq(md, min) {
        width: 55%;
    }
}