body {
    background: var(--color-body);
    color: var(--gray-10);
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

a {
    text-decoration: none;

    &:focus {
        outline: 0;
    }

    &:hover, &:active {
        outline: 0;
        opacity: .3;
        transition: all 0.3s;
    }

    img {
        border: 0;
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?1y6173');
    src:  url('fonts/icomoon.eot?1y6173#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1y6173') format('truetype'),
    url('fonts/icomoon.woff?1y6173') format('woff'),
    url('fonts/icomoon.svg?1y6173#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook2:before {
    content: "\ea91";
}
.icon-instagram:before {
    content: "\ea92";
}
.icon-twitter:before {
    content: "\ea96";
}
@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?1y6173');
    src:  url('fonts/icomoon.eot?1y6173#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1y6173') format('truetype'),
    url('fonts/icomoon.woff?1y6173') format('woff'),
    url('fonts/icomoon.svg?1y6173#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook2:before {
    content: "\ea91";
}
.icon-instagram:before {
    content: "\ea92";
}
.icon-twitter:before {
    content: "\ea96";
}
