#about {
    h3 {
        margin-bottom: 30px;
        font-size: 2.4rem;

        &:first-letter {
            font-size: 3.6rem;
        }
    }

    .l-mainwrap p {
        padding: 0 15px 15px;
        line-height: 2;
    }
}

@media screen and (max-width: 767px) {
    #about {
        h3 {
            margin-bottom: 15px;
            font-size: 1.6rem;
        }

        .l-mainwrap p {
            font-size: 1.2rem;
        }
    }
}

@media screen and (min-width: 768px) {
    #about {
        .content-inner {
            position: relative;
            max-width: 700px;
            margin: 0 auto;
        }

        .content-img {
            width: 70%;
        }

        .content-txt {
            position: absolute;
            top: 50%;
            bottom: 50%;
            right: 0;
            max-width: 400px;
        }
    }
}