/* footer
-------------------------------------------------------------------------------------　*/
.l-footer {
    background: var(--gray-2);
    overflow: hidden;

    &_logo {
        @include mq(md) {
            width: 117px;
            height: 85px;
            margin: 15px 0;
        }
        @include mq(md, min) {
            width: 156px;
            height: 114px;
            margin: 15px 30px 15px 0;
        }
    }

    .sns-block {
        @include mq(md) {
            display: none;
        }
        @include mq(md, min) {
            display: table-cell;
            width: 30%;
            vertical-align: middle;
            text-align: left;

            .facebook-link,
            .twitter-link {
                margin-bottom: 8px;
            }
        }
    }

    @include mq(md, min) {
        .title-copy-block {
            display: table-cell;
            width: 70%;
        }

        .sns-link a {
            margin-bottom: 15px;
            background: #ccc;
            color: var(--gray-10);
        }
    }
}

.l-copyright {
    display: inline-block;
    padding: 3px 0;
    font-size: 1.1rem;
    color: var(--gray-10);
    text-align: center;

    a {
        font-size: 1.1rem;
        color: var(--gray-10);
        text-decoration: none;
    }

    @media screen and (max-width: 767px) {
        display: block;
        margin-bottom: 45px;
    }
}