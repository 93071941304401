.c_nav {
    @include mq(md) {
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: $zindex-fixed;
        background: var(--white);
    }
    @include mq(md, min) {
        flex: 1;
    }

    &_item {
        @include mq(md) {
            width: 50%;
            border-bottom: 1px solid var(--gray-2);
            &:nth-child(odd) {
                border-right: 1px solid var(--gray-2);
            }
            &:hover {
                background: var(--color-accent);
                background-size: 100% 80%;
                color: var(--white);
            }
        }
        @include mq(md, min) {
            color: var(--white);
            &:hover {
                border-bottom: 1px solid var(--gray-6);
                color: var(--gray-6);
            }
        }
    }

    &_link {
        &:hover {
            opacity: 1;
        }
        @include mq(md) {
            font-size: 1.2rem;
        }
        @include mq(md, min) {
            font-size: 1.6rem;
        }
    }

    &_sns {
        @include mq(md) {
            background: var(--gray-1);
        }
    }

    &_toggle {
        @include mq(md) {
            width: 100%;
            background: var(--color-primary);

            &:before {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 10px;
                width: 20px;
                height: 20px;
                margin-top: -10px;
                background: var(--white);
            }

            &_link {
                padding: 12px 0 10px;
                color: var(--white);

                &:hover {
                    opacity: 1;
                }

                &:before, &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    width: 20px;
                    height: 4px;
                    background: var(--color-primary);
                }

                &:before {
                    margin-top: -6px;
                }

                &:after {
                    margin-top: 2px;
                }
            }
        }
        @include mq(md, min) {
            display: none;
        }
    }
}

.is-menu-close {
    @include mq(md) {
        display: none;
    }
}

.is-page-selected {
    @include mq(md) {
        background: var(--color-accent);
        background-size: 100% 80%;
        color: var(--white);
    }
    @include mq(md, min) {
        border-bottom: 1px solid var(--gray-6);
        color: var(--gray-6);
    }
}
