.pagination {
    display: inline-block;
    overflow: hidden;
    clear: both;
    padding: 30px 0 0;
    position: relative;
    font-size: 1.1rem;
    line-height: 13px;

    span {
        display: block;
        float: left;
        margin: 2px 5px 2px 0;
        padding: 6px 9px 5px 9px;
        text-decoration: none;
        width: auto;
        color: #ffffff;
        border: solid 1px #6A3906;
        background: url(../../images/common/h3_bg.png) 0 50% repeat-x;
        background: #6A3906;
    }

    a {
        display: block;
        float: left;
        margin: 2px 5px 2px 0;
        padding: 6px 9px 5px 9px;
        text-decoration: none;
        width: auto;
        color: #ffffff;
        border: solid 1px #6A3906;
        background: url(../../images/common/h3_bg.png) 0 50% repeat-x;
        background: #6A3906;

        &:hover {
            background: #BCDFD6;
            color: #6A3906;
            border: solid 1px #BCDFD6;
        }
    }

    .current {
        background: #BCDFD6;
        color: #6A3906;
        padding: 6px 9px 5px 9px;
        border: solid 1px #BCDFD6;
    }
}