.p_header {
    &_bg {
        background: var(--color-primary);
    }

    &_logo {
        width: 38px;
        height: 58px;
    }

    &_contactLink {
        border-radius: 5px;
        background: var(--gray-10);
        color: var(--white);

        &:visited {
            color: var(--white);
        }

        @include mq(md, min) {
            &:hover {
                background: var(--white);
                color: var(--gray-10);
                font-weight: $font-weight-bold;
                opacity: 1;
            }
        }
    }
}
