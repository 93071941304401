.gallery_list {
    @include mq(md) {
        width: 45%;
        margin-right: 10%;
        &:nth-child(even) {
            margin-right: 0;
        }
        margin-bottom: 15px;
    }
    @include mq(md, min) {
        width: 30%;
        margin-right: 5%;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }

}

.gallery_img {
    box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 3px 1px;
}
