#contact {
    .personal {
        width: 80%;
        margin: 0 auto 30px;
        line-height: 1.8em;
    }

    h3 {
        display: block;
        margin-bottom: 15px;
        background: #EEE;
        font-weight: bold;
        color: #000;

        .title {
            display: block;
            float: none;
            padding: 10px 15px;
            font-size: 1.8rem;
        }
    }

    .content-dir {
        background: rgba(255, 255, 255, 0.5);
    }
}

#contact-des p {
    line-height: 1.8em;
}

/***** コンタクトフォーム *****/

.requiredmark {
    margin: 5px 0;
    color: #F00;
}

#formlist {
    width: 100%;
    text-align: left;

    th {
        width: 30%;
        padding: 15px 15px;
        background: #e5fdff;
        vertical-align: middle;
        box-sizing: border-box;
    }

    td {
        width: 70%;
        padding: 5px 15px;
        vertical-align: middle;
        box-sizing: border-box;
    }

    input {
        &[type=text] {
            &#zip, &#pref {
                width: 50%;
            }

            width: 98%;
            padding: 5px;
            font-size: 1.4rem;
        }

        &[type=email] {
            width: 98%;
            padding: 5px;
            font-size: 1.4rem;
        }
    }

    textarea {
        width: 98%;
        padding: 5px;
        font-size: 1.4rem;
    }

    input[type=tel] {
        width: 20%;
        padding: 5px;
        font-size: 1.4rem;
    }

    .file {
        margin: 10px;
        display: block;
    }

    span.wpcf7-list-item {
        display: block;
    }

    input {
        &[type=text]:focus, &[type=email]:focus, &[type=tel]:focus {
            border: solid 1px #333;
        }

        &[type=text] {
            outline: none;
        }
    }

    select {
        outline: none;
    }
}

#formlistinput[type=email], #formlist input[type=tel] {
    outline: none;
}

div.wpcf7-response-output {
    text-align: center;
    border: 2px solid #FF0000;
}

#contact {
    .acceptance {
        text-align: center;
        width: 90%;
        padding: 10px;
        margin: 30px auto 15px;
        background: #EEE;

        span.notice {
            font-size: 1.2rem;
        }
    }

    input {
        &[type=submit] {
            display: block;
            width: 89%;
            padding: 15px;
            margin: 0 auto;
            background: #a0dfe3;
            font-size: 1.8rem;
            font-weight: bold;
            cursor: pointer;
        }

        &[type="submit"][disabled] {
            background: #EEE;
            cursor: not-allowed;
            /* 禁止カーソル */
        }

        &[type=submit][disabled]:hover {
            background: #DDD;
            color: #000;
            font-weight: blod;
        }
    }
}

@media screen and (max-width: 767px) {
    #formlist {
        tr {
            width: 100%;
        }

        th, td {
            display: block;
            width: 100%;
        }
    }
    #contact .contact-img {
        display: none;
    }
    #formlist input[type=tel] {
        width: 4em;
    }
}