@each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @each $value in $displays {
            .u-display#{$infix}-#{$value} {
                display: $value !important;
            }
        }
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        .u-flex#{$infix}-row {
            flex-direction: row !important;
        }
        .u-flex#{$infix}-column {
            flex-direction: column !important;
        }
        .u-flex#{$infix}-row-reverse {
            flex-direction: row-reverse !important;
        }
        .u-flex#{$infix}-column-reverse {
            flex-direction: column-reverse !important;
        }

        .u-flex#{$infix}-wrap {
            flex-wrap: wrap !important;
        }
        .u-flex#{$infix}-nowrap {
            flex-wrap: nowrap !important;
        }
        .u-flex#{$infix}-wrap-reverse {
            flex-wrap: wrap-reverse !important;
        }
        .u-flex#{$infix}-fill {
            flex: 1 1 auto !important;
        }
        .u-flex#{$infix}-grow-0 {
            flex-grow: 0 !important;
        }
        .u-flex#{$infix}-grow-1 {
            flex-grow: 1 !important;
        }
        .u-flex#{$infix}-shrink-0 {
            flex-shrink: 0 !important;
        }
        .u-flex#{$infix}-shrink-1 {
            flex-shrink: 1 !important;
        }

        .u-justify-content#{$infix}-start {
            justify-content: flex-start !important;
        }
        .u-justify-content#{$infix}-end {
            justify-content: flex-end !important;
        }
        .u-justify-content#{$infix}-center {
            justify-content: center !important;
        }
        .u-justify-content#{$infix}-between {
            justify-content: space-between !important;
        }
        .u-justify-content#{$infix}-around {
            justify-content: space-around !important;
        }

        .u-align-items#{$infix}-start {
            align-items: flex-start !important;
        }
        .u-align-items#{$infix}-end {
            align-items: flex-end !important;
        }
        .u-align-items#{$infix}-center {
            align-items: center !important;
        }
        .u-align-items#{$infix}-baseline {
            align-items: baseline !important;
        }
        .u-align-items#{$infix}-stretch {
            align-items: stretch !important;
        }

        .u-align-content#{$infix}-start {
            align-content: flex-start !important;
        }
        .u-align-content#{$infix}-end {
            align-content: flex-end !important;
        }
        .u-align-content#{$infix}-center {
            align-content: center !important;
        }
        .u-align-content#{$infix}-between {
            align-content: space-between !important;
        }
        .u-align-content#{$infix}-around {
            align-content: space-around !important;
        }
        .u-align-content#{$infix}-stretch {
            align-content: stretch !important;
        }

        .u-align-self#{$infix}-auto {
            align-self: auto !important;
        }
        .u-align-self#{$infix}-start {
            align-self: flex-start !important;
        }
        .u-align-self#{$infix}-end {
            align-self: flex-end !important;
        }
        .u-align-self#{$infix}-center {
            align-self: center !important;
        }
        .u-align-self#{$infix}-baseline {
            align-self: baseline !important;
        }
        .u-align-self#{$infix}-stretch {
            align-self: stretch !important;
        }
    }
}

@each $value in $overflows {
    .u-overflow-#{$value} {
        overflow: $value !important;
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);
        @each $position in $positions {
            .u-position#{$infix}-#{$position} {
                position: $position !important;
            }
        }
    }
}

.u-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.u-fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.u-sticky-top {
    @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
    }
}

/* spacing
-------------------------------------------------------------------------------------　*/
@each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
                .u-#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }
                .u-#{$abbrev}t#{$infix}-#{$size},
                .u-#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }
                .u-#{$abbrev}r#{$infix}-#{$size},
                .u-#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }
                .u-#{$abbrev}b#{$infix}-#{$size},
                .u-#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }
                .u-#{$abbrev}l#{$infix}-#{$size},
                .u-#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
            }
        }

        // margin
        @each $size, $length in $spacers {
            @if $size != 0 {
                .u-m#{$infix}-n#{$size} {
                    margin: -$length !important;
                }
                .u-mt#{$infix}-n#{$size},
                .u-my#{$infix}-n#{$size} {
                    margin-top: -$length !important;
                }
                .u-mr#{$infix}-n#{$size},
                .u-mx#{$infix}-n#{$size} {
                    margin-right: -$length !important;
                }
                .u-mb#{$infix}-n#{$size},
                .u-my#{$infix}-n#{$size} {
                    margin-bottom: -$length !important;
                }
                .u-ml#{$infix}-n#{$size},
                .u-mx#{$infix}-n#{$size} {
                    margin-left: -$length !important;
                }
            }
        }

        // margin: auto
        .u-m#{$infix}-auto {
            margin: auto !important;
        }
        .u-mt#{$infix}-auto,
        .u-my#{$infix}-auto {
            margin-top: auto !important;
        }
        .u-mr#{$infix}-auto,
        .u-mx#{$infix}-auto {
            margin-right: auto !important;
        }
        .u-mb#{$infix}-auto,
        .u-my#{$infix}-auto {
            margin-bottom: auto !important;
        }
        .u-ml#{$infix}-auto,
        .u-mx#{$infix}-auto {
            margin-left: auto !important;
        }
    }
}

.br_sp {
    display: none;
    @include mq(md) {
        display: block;

    }
}

.br_pc {
    display: block;
    @include mq(md) {
        display: none;
    }
}

.spview {
    @include mq(md) {
        display: block;
    }
    @include mq(md, min) {
        display: none;
    }
}

.sp-taview {
    @include mq(lg) {
        display: block;
    }
    @include mq(lg, min) {
        display: none;
    }
}

.taview {
    @include mq(md) {
        display: none;
    }
    @include mq(md, min) {
        display: block;
    }
    @include mq(lg, min) {
        display: none;
    }
}

.ta-pcview {
    @include mq(md) {
        display: none;
    }
    @include mq(md, min) {
        display: block;
    }
}

.pcview {
    @include mq(lg) {
        display: none;
    }
    @include mq(lg, min) {
        display: block;
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}