.single {
    #content.l-mainwrap {
        padding: 30px;
        margin-bottom: 60px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 3px 1px;
        @include mq(md) {
            padding: 5px;
        }
    }

    .entry-content {
        line-height: 1.5em;

        p {
            line-height: 2;

        }

        img {
            display: block;
            margin: 30px auto 0;
            line-height: 1.5em;
            box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.4);
        }
    }
}

.p_single_title {
    font-size: 2.6rem;
    line-height: 1.4;
    @include mq(md) {
        font-size: 1.8rem;
    }
}

/* single page
-------------- ----------------------------------------------------------------------　*/

/* single_pager ---------------------------*/

.single_pager {
    margin-bottom: 30px;

    a:hover {
        color: #009900;
        text-decoration: underline;
    }

    .left {
        float: left;
    }

    .right {
        float: right;
    }
}